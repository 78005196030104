<template>
  <div class="page-container">
    <h1>Change password</h1>
    <el-form
      style="margin-top: 40px; max-width: 500px"
      :model="ruleForm2"
      status-icon
      :rules="rules2"
      ref="ruleForm2"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="Password" prop="age">
        <el-input
          type="password"
          v-model="ruleForm2.pass"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="New password" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm2.newPass"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="Confirm Password" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm2.checkPass"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm2')"
          >Submit</el-button
        >
        <el-button @click="resetForm('ruleForm2')">Reset</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Md5 from 'md5'
import { setPsw } from '@/api/user.js'
export default {
  name: 'ModifyPasswordPage',
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm2.checkPass !== '') {
          this.$refs.ruleForm2.validateField('checkPass')
        }
        callback()
      }
    }
    var validateNewPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.ruleForm2.checkPass !== '') {
          this.$refs.ruleForm2.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入新密码'))
      } else if (value !== this.ruleForm2.newPass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm2: {
        pass: '',
        newPass: '',
        checkPass: '',
        age: ''
      },
      rules2: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        newPass: [{ validator: validateNewPass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.sendModifyPassword()
        } else {
          window.console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    async sendModifyPassword() {
      setPsw({
        oldPwd: Md5(this.ruleForm2.pass),
        newPwd: Md5(this.ruleForm2.newPass),
        againNewPwd: Md5(this.ruleForm2.checkPass)
      })
        .then(
          res => {
            if (res.data.code === 0) {
              window.sessionStorage.clear()
              location.reload()
            } else if (res.data.code === 1001) {
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
              window.sessionStorage.clear()
              location.reload()
            } else {
              return this.$message({
                showClose: true,
                message: res.data.msg,
                type: 'error'
              })
            }
          },
          err => {
            return this.$message({
              showClose: true,
              message: '请求错误:' + err,
              type: 'error'
            })
          }
        )
        .then(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  width: 200px;
}
</style>
